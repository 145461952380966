/// <reference types="./computed.d.mts" />
import * as $project from "../../../common/data/project.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $question from "../../../common/data/question.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Computed extends $CustomType {
  constructor(projects, projects_count, displayed_questions) {
    super();
    this.projects = projects;
    this.projects_count = projects_count;
    this.displayed_questions = displayed_questions;
  }
}

export class Projects extends $CustomType {
  constructor(all, ongoing, finished) {
    super();
    this.all = all;
    this.ongoing = ongoing;
    this.finished = finished;
  }
}

export function new$() {
  let projects = new Projects(toList([]), toList([]), toList([]));
  let projects_count = new Projects(0, 0, 0);
  return new Computed(projects, projects_count, toList([]));
}
