/// <reference types="./ai_search.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";

export function card(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($common.card()), $s.flex("1")]));
  return $h.div(_pipe, attrs, children);
}

export function card_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($common.card()),
      $s.flex("1"),
      $s.display("flex"),
      $s.gap(px(16)),
      $s.overflow("auto"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_title(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.compose($display.xs()), $s.color($colors.light.onyx)]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_subtitle(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.regular)),
      $s.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function response_source(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.display("flex"),
      $s.line_height("2"),
      $s.gap(px(12)),
      $s.color($colors.light.grey_800),
      $s.align_items("center"),
      $s.cursor("default"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
