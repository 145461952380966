/// <reference types="./skeleton.d.mts" />
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import { toList } from "../../gleam.mjs";

function skeleton_loader(child) {
  let _pipe = $s.class$(
    toList([
      $s.position("absolute"),
      $s.top(px(0)),
      $s.bottom(px(0)),
      $s.left(px(0)),
      $s.right(px(0)),
      $s.background($colors.light.grey_50),
      $s.overflow("hidden"),
      $s.border_radius(px(4)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

function skeleton_wave() {
  let _pipe = $s.class$(
    toList([
      $s.position("relative"),
      $s.overflow("hidden"),
      $s.background(
        ("linear-gradient(90deg, transparent, " + $colors.light.grey_200) + ", transparent)",
      ),
      $s.animation("wave 1s linear 0.5s infinite alternate"),
      $s.transform("translateX(100%)"),
      $s.position("absolute"),
      $s.top(px(0)),
      $s.bottom(px(0)),
      $s.left(px(0)),
      $s.right(px(0)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function skeleton(width, height) {
  let _pipe = $s.class$(
    toList([$s.position("relative"), $s.width(px(width)), $s.height(px(height))]),
  );
  return $h.div(_pipe, toList([]), toList([skeleton_loader(skeleton_wave())]));
}

export function full_width(height) {
  let _pipe = $s.class$(
    toList([
      $s.position("relative"),
      $s.width(percent(100)),
      $s.height(px(height)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([skeleton_loader(skeleton_wave())]));
}

export function hide_loading(value, width, height, next) {
  if (!value.isOk()) {
    return skeleton(width, height);
  } else {
    let value$1 = value[0];
    return next(value$1);
  }
}
