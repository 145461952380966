/// <reference types="./options.d.mts" />
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";

class Options extends $CustomType {
  constructor(domain, client_id, use_refresh_token, use_refresh_token_fallback, authorization_params) {
    super();
    this.domain = domain;
    this.client_id = client_id;
    this.use_refresh_token = use_refresh_token;
    this.use_refresh_token_fallback = use_refresh_token_fallback;
    this.authorization_params = authorization_params;
  }
}

class AuthorizationParams extends $CustomType {
  constructor(audience, scope, connection, organization, invitation, redirect_uri, custom_parameters) {
    super();
    this.audience = audience;
    this.scope = scope;
    this.connection = connection;
    this.organization = organization;
    this.invitation = invitation;
    this.redirect_uri = redirect_uri;
    this.custom_parameters = custom_parameters;
  }
}

export function new$(domain, client_id) {
  return new Options(domain, client_id, false, true, new None());
}

export function use_refresh_token(options, use_refresh_token) {
  let _record = options;
  return new Options(
    _record.domain,
    _record.client_id,
    use_refresh_token,
    _record.use_refresh_token_fallback,
    _record.authorization_params,
  );
}

export function use_refresh_token_fallback(options, use_refresh_token_fallback) {
  let _record = options;
  return new Options(
    _record.domain,
    _record.client_id,
    _record.use_refresh_token,
    use_refresh_token_fallback,
    _record.authorization_params,
  );
}

export function with_authorization_params(options, authorization_params) {
  let _record = options;
  return new Options(
    _record.domain,
    _record.client_id,
    _record.use_refresh_token,
    _record.use_refresh_token_fallback,
    new Some(authorization_params),
  );
}

export function authorization_params() {
  return new AuthorizationParams(
    new None(),
    new None(),
    new None(),
    new None(),
    new None(),
    new None(),
    new None(),
  );
}

export function audience(params, audience) {
  let _record = params;
  return new AuthorizationParams(
    new Some(audience),
    _record.scope,
    _record.connection,
    _record.organization,
    _record.invitation,
    _record.redirect_uri,
    _record.custom_parameters,
  );
}

export function scope(params, scope) {
  let _record = params;
  return new AuthorizationParams(
    _record.audience,
    new Some(scope),
    _record.connection,
    _record.organization,
    _record.invitation,
    _record.redirect_uri,
    _record.custom_parameters,
  );
}

export function connection(params, connection) {
  let _record = params;
  return new AuthorizationParams(
    _record.audience,
    _record.scope,
    new Some(connection),
    _record.organization,
    _record.invitation,
    _record.redirect_uri,
    _record.custom_parameters,
  );
}

export function organization(params, organization) {
  let _record = params;
  return new AuthorizationParams(
    _record.audience,
    _record.scope,
    _record.connection,
    new Some(organization),
    _record.invitation,
    _record.redirect_uri,
    _record.custom_parameters,
  );
}

export function invitation(params, invitation) {
  let _record = params;
  return new AuthorizationParams(
    _record.audience,
    _record.scope,
    _record.connection,
    _record.organization,
    new Some(invitation),
    _record.redirect_uri,
    _record.custom_parameters,
  );
}

export function redirect_uri(params, redirect_uri) {
  let _record = params;
  return new AuthorizationParams(
    _record.audience,
    _record.scope,
    _record.connection,
    _record.organization,
    _record.invitation,
    new Some(redirect_uri),
    _record.custom_parameters,
  );
}

export function custom_parameter(params, parameter, value) {
  let _pipe = params.custom_parameters;
  let _pipe$1 = $option.unwrap(_pipe, $dict.new$());
  let _pipe$2 = $dict.insert(_pipe$1, parameter, value);
  return ((d) => {
    let _record = params;
    return new AuthorizationParams(
      _record.audience,
      _record.scope,
      _record.connection,
      _record.organization,
      _record.invitation,
      _record.redirect_uri,
      new Some(d),
    );
  })(_pipe$2);
}

export function connection_scope(params, scope) {
  return custom_parameter(params, "connection_scope", scope);
}
