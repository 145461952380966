/// <reference types="./dynamic.d.mts" />
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import { Ok, Error } from "../../gleam.mjs";

export function map(decoder, with$) {
  return (item) => {
    let $ = decoder(item);
    if ($.isOk()) {
      let item$1 = $[0];
      return new Ok(with$(item$1));
    } else {
      let errors = $[0];
      return new Error(errors);
    }
  };
}
