/// <reference types="./layout.d.mts" />
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $el from "../../../sketch_magic/sketch/magic/element.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

class Gap extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Align extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Justify extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Flex extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Tag extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Padding extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Position extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Overflow extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Class extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Wrap extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Attributes extends $CustomType {
  constructor(gap, align, justify, flex, tag, padding, position, overflow, class$, wrap) {
    super();
    this.gap = gap;
    this.align = align;
    this.justify = justify;
    this.flex = flex;
    this.tag = tag;
    this.padding = padding;
    this.position = position;
    this.overflow = overflow;
    this.class = class$;
    this.wrap = wrap;
  }
}

export function class$(class$) {
  return new Class(class$);
}

export function overflow(value) {
  return new Overflow(value);
}

export function gap(value) {
  return new Gap(value);
}

export function align(value) {
  return new Align(value);
}

export function justify(value) {
  return new Justify(value);
}

export function flex(value) {
  return new Flex(value);
}

export function tag(value) {
  return new Tag(value);
}

export function padding(value) {
  return new Padding(value);
}

export function position(value) {
  return new Position(value);
}

export function wrap(value) {
  return new Wrap(value);
}

function compute_attributes(attrs) {
  let new$ = new Attributes(
    0,
    "stretch",
    "stretch",
    new None(),
    "div",
    0,
    "static",
    new $option.None(),
    new $option.None(),
    new $option.None(),
  );
  return $list.fold(
    attrs,
    new$,
    (acc, val) => {
      if (val instanceof Gap) {
        let gap$1 = val[0];
        let _record = acc;
        return new Attributes(
          gap$1,
          _record.align,
          _record.justify,
          _record.flex,
          _record.tag,
          _record.padding,
          _record.position,
          _record.overflow,
          _record.class,
          _record.wrap,
        );
      } else if (val instanceof Align) {
        let align$1 = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          align$1,
          _record.justify,
          _record.flex,
          _record.tag,
          _record.padding,
          _record.position,
          _record.overflow,
          _record.class,
          _record.wrap,
        );
      } else if (val instanceof Justify) {
        let justify$1 = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          _record.align,
          justify$1,
          _record.flex,
          _record.tag,
          _record.padding,
          _record.position,
          _record.overflow,
          _record.class,
          _record.wrap,
        );
      } else if (val instanceof Flex) {
        let value = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          _record.align,
          _record.justify,
          new Some(value),
          _record.tag,
          _record.padding,
          _record.position,
          _record.overflow,
          _record.class,
          _record.wrap,
        );
      } else if (val instanceof Tag) {
        let tag$1 = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          _record.align,
          _record.justify,
          _record.flex,
          tag$1,
          _record.padding,
          _record.position,
          _record.overflow,
          _record.class,
          _record.wrap,
        );
      } else if (val instanceof Padding) {
        let padding$1 = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          _record.align,
          _record.justify,
          _record.flex,
          _record.tag,
          padding$1,
          _record.position,
          _record.overflow,
          _record.class,
          _record.wrap,
        );
      } else if (val instanceof Position) {
        let position$1 = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          _record.align,
          _record.justify,
          _record.flex,
          _record.tag,
          _record.padding,
          position$1,
          _record.overflow,
          _record.class,
          _record.wrap,
        );
      } else if (val instanceof Overflow) {
        let overflow$1 = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          _record.align,
          _record.justify,
          _record.flex,
          _record.tag,
          _record.padding,
          _record.position,
          new Some(overflow$1),
          _record.class,
          _record.wrap,
        );
      } else if (val instanceof Class) {
        let class$1 = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          _record.align,
          _record.justify,
          _record.flex,
          _record.tag,
          _record.padding,
          _record.position,
          _record.overflow,
          new Some(class$1),
          _record.wrap,
        );
      } else {
        let wrap$1 = val[0];
        let _record = acc;
        return new Attributes(
          _record.gap,
          _record.align,
          _record.justify,
          _record.flex,
          _record.tag,
          _record.padding,
          _record.position,
          _record.overflow,
          _record.class,
          new Some(wrap$1),
        );
      }
    },
  );
}

export function row(row_attrs, attrs, children) {
  let styles = compute_attributes(row_attrs);
  let _pipe = $s.class$(
    toList([
      (() => {
        let $ = styles.class;
        if ($ instanceof $option.None) {
          return $s.none();
        } else {
          let class$1 = $[0];
          return $s.compose(class$1);
        }
      })(),
      $s.display("flex"),
      $s.flex_direction("row"),
      $s.align_items(styles.align),
      $s.justify_content(styles.justify),
      $s.gap(px(styles.gap)),
      $s.padding(px(styles.padding)),
      $s.position(styles.position),
      $s.overflow(
        (() => {
          let $ = styles.overflow;
          if ($ instanceof Some) {
            let value = $[0];
            return value;
          } else {
            return "visible";
          }
        })(),
      ),
      $s.flex(
        (() => {
          let $ = styles.flex;
          if ($ instanceof Some) {
            let value = $[0];
            return value;
          } else {
            return "initial";
          }
        })(),
      ),
      (() => {
        let $ = styles.wrap;
        if ($ instanceof None) {
          return $s.none();
        } else {
          let wrap$1 = $[0];
          return $s.flex_wrap(wrap$1);
        }
      })(),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function column(column_attrs, attrs, children) {
  let styles = compute_attributes(column_attrs);
  let _pipe = $s.class$(
    toList([
      (() => {
        let $ = styles.class;
        if ($ instanceof $option.None) {
          return $s.none();
        } else {
          let class$1 = $[0];
          return $s.compose(class$1);
        }
      })(),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.align_items(styles.align),
      $s.justify_content(styles.justify),
      $s.gap(px(styles.gap)),
      $s.padding(px(styles.padding)),
      $s.position(styles.position),
      $s.overflow(
        (() => {
          let $ = styles.overflow;
          if ($ instanceof Some) {
            let value = $[0];
            return value;
          } else {
            return "visible";
          }
        })(),
      ),
      $s.flex(
        (() => {
          let $ = styles.flex;
          if ($ instanceof Some) {
            let value = $[0];
            return value;
          } else {
            return "initial";
          }
        })(),
      ),
    ]),
  );
  return ((_capture) => {
    return $el.element(styles.tag, _capture, attrs, children);
  })(_pipe);
}
