/// <reference types="./ai_search.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $input from "../../ds/ds/components/input.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $icons from "../../ds/ds/icons.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import { toList } from "../gleam.mjs";
import * as $s from "../view/styles/ai_search.mjs";

function format_search_result_title(index, total, score, source_name) {
  let dp_index = $int.to_string(index + 1);
  let dp_score = $float.to_string(
    (() => {
      let _pipe = score;
      return $float.to_precision(_pipe, 2);
    })(),
  );
  return ((((((("[DP: " + dp_index) + "/") + $int.to_string(total)) + "] [Score : ") + dp_score) + "] ") + source_name) + "\n";
}

function display_search_result(data, index, search_result) {
  let data_point = search_result[0];
  let score = search_result[1];
  let source_name = (() => {
    let _pipe = data.content_library.non_qna_sources.all;
    let _pipe$1 = $list.find(
      _pipe,
      (source) => { return source.id === data_point.source_id; },
    );
    let _pipe$2 = $result.map(
      _pipe$1,
      (source) => { return source.display.name; },
    );
    return $result.unwrap(_pipe$2, "");
  })();
  let total = (() => {
    let _pipe = data.ai_search_results;
    return $list.length(_pipe);
  })();
  return $layout.column(
    toList([]),
    toList([]),
    toList([
      $s.card_title(
        toList([]),
        toList([
          $h.text(format_search_result_title(index, total, score, source_name)),
        ]),
      ),
      $s.response_source(
        toList([]),
        toList([
          $h.div(
            toList([
              $a.attribute(
                "dangerous-unescaped-html",
                $option.unwrap(data_point.display.as_html, data_point.text),
              ),
            ]),
            toList([]),
          ),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let data = $bright.data(model);
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(8), $layout.flex("1")]),
        toList([]),
        toList([
          $h.form(
            toList([$e.on_submit(new $msg.UserSubmittedAiSearch())]),
            toList([
              $input.input(
                toList([
                  $input.multiline(),
                  $input.placeholder("Ask steerlab a question…"),
                  $input.icon($icons.paper_plane()),
                  $input.on_input(
                    (var0) => { return new $msg.UserUpdatedSearchInput(var0); },
                  ),
                  $input.on_submit(new $msg.UserSubmittedAiSearch()),
                  $input.value(data.ai_search_input),
                ]),
              ),
            ]),
          ),
          $layout.column(
            toList([$layout.gap(16)]),
            toList([]),
            $list.index_map(
              data.ai_search_results,
              (ai_search_result, index) => {
                return $s.card_wrapper(
                  toList([]),
                  toList([display_search_result(data, index, ai_search_result)]),
                );
              },
            ),
          ),
        ]),
      ),
    ]),
  );
}
