/// <reference types="./show.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $skeleton from "../../../ds/ds/components/skeleton.mjs";
import * as $table from "../../../ds/ds/components/table.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList, prepend as listPrepend } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";
import * as $s from "../../view/styles/content_library.mjs";

function view_loading_table() {
  let _pipe = $table.row(
    listPrepend(
      $table.cell($el.none()),
      (() => {
        let _pipe = $table.cell($skeleton.skeleton(100, 25));
        return $list.repeat(_pipe, 4);
      })(),
    ),
  );
  let _pipe$1 = $list.repeat(_pipe, 4);
  return $table.body(_pipe$1);
}

function content_library_table_headers() {
  return $table.headers(
    toList([
      (() => {
        let _pipe = $table.header("Format");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Name");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Language");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("");
        return $table.fit(_pipe);
      })(),
    ]),
  );
}

function view_table_body(model) {
  let $ = $bright.unwrap(model);
  let model$1 = $[0];
  return $table.body(
    $list.map(
      (() => {
        let _pipe = model$1.content_library;
        return $content_library.filtered_non_qna_sources(_pipe);
      })(),
      (source) => {
        let _pipe = $table.row(
          toList([
            $table.cell(
              $common.small_icon($common.select_icon(source.display.mime_type)),
            ),
            $table.cell($el.text(source.display.name)),
            (() => {
              let _pipe = $table.cell(
                $h.div(
                  toList([]),
                  toList([
                    $h.text(
                      (() => {
                        let $1 = source.display.language;
                        if ($1 instanceof $option.None) {
                          return "";
                        } else if ($1 instanceof $option.Some && $1[0] === "") {
                          return "";
                        } else {
                          let language = $1[0];
                          return $translate.from_iso_string_to_display_string(
                            language,
                          );
                        }
                      })(),
                    ),
                  ]),
                ),
              );
              return $table.justify(_pipe, "start");
            })(),
          ]),
        );
        return $table.on_click(_pipe, new $msg.UserClickedOnSource(source.id));
      },
    ),
  );
}

const empty_text = "Connect your Drive, or upload directly files, directly with the button above.";

function empty_cell() {
  return $s.empty_cell($s.empty_text_content($h.text(empty_text)));
}

function view_empty_table() {
  return $table.body(
    toList([
      (() => {
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(empty_cell());
              return $table.span(_pipe, 7);
            })(),
          ]),
        );
        return $table.hover(_pipe, false);
      })(),
    ]),
  );
}

function content_library_table(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $table.table(
    toList([
      content_library_table_headers(),
      (() => {
        let $1 = data.loading.content_library;
        let $2 = $list.is_empty(data.content_library.non_qna_sources.all);
        if ($1) {
          return view_loading_table();
        } else if ($2) {
          return view_empty_table();
        } else {
          return view_table_body(model);
        }
      })(),
    ]),
  );
}

export function view(model) {
  return $el.fragment(
    toList([
      (() => {
        let _pipe = content_library_table(model);
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
    ]),
  );
}
