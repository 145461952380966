/// <reference types="./middleware.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client/user.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $ffi from "../admin/ffi.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";

export function require_access_token(data, next) {
  let $ = data.access_token;
  if ($ instanceof $option.Some) {
    let access_token = $[0];
    return next(access_token);
  } else {
    return $effect.none();
  }
}

export function require_permission(data, permission, next) {
  let $ = $list.contains(data.permissions, permission);
  if ($) {
    return next();
  } else {
    return $effect.none();
  }
}

export function require_super_admin(data, next) {
  return require_permission(data, "super-admin", next);
}

export function require_org_id(data, next) {
  let org_id = $option.then$(data.user, $auth0.org_id);
  if (org_id instanceof $option.Some) {
    let org_id$1 = org_id[0];
    return next(org_id$1);
  } else {
    return $effect.none();
  }
}

export function require_user_id(data, next) {
  let user = $option.map(data.user, $auth0.id);
  if (user instanceof $option.Some) {
    let user_id = user[0];
    return next(user_id);
  } else {
    return $effect.none();
  }
}

export function from_promise(promise, next) {
  return $effect.from(
    (dispatch) => {
      let _pipe = promise;
      $promise.map(_pipe, (a) => { return next(dispatch, a); })
      return undefined;
    },
  );
}

export function require_not_modifying_question(data, next) {
  let $ = $ffi.get_active_element_nearest_id();
  if ($.isOk() && $[0].startsWith("question")) {
    return [data, $effect.none()];
  } else if ($.isOk() && $[0] === "input-proposal-name") {
    return [data, $effect.none()];
  } else if ($.isOk()) {
    return next();
  } else {
    return next();
  }
}
