/// <reference types="./style_guidelines.d.mts" />
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $ask from "../../admin/ask.mjs";
import * as $middleware from "../../admin/middleware.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList } from "../../gleam.mjs";

export function set_style_guidelines(data, content) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["style-guidelines"]),
              );
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(toList([["content", $json.string(content)]])),
              );
              let _pipe$4 = $ask.expect(
                _pipe$3,
                $dynamic.field("content", $dynamic.optional($dynamic.string)),
              );
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (value) => {
                  return dispatch(new $msg.ApiSavedStyleGuidelines(value));
                },
              );
              $ask.run(_pipe$5)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function send_style_guidelines_generation_request(data) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $middleware.require_org_id(
            data,
            (org_id) => {
              return $effect.from(
                (dispatch) => {
                  let _pipe = $ask.to(
                    new $ask.Nabu(),
                    toList(["style-guidelines"]),
                  );
                  let _pipe$1 = $ask.via(_pipe, new $http.Post());
                  let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                  let _pipe$3 = $ask.with$(
                    _pipe$2,
                    $json.object(
                      toList([
                        ["org_id", $json.string(org_id)],
                        [
                          "content",
                          $json.string(data.questions_answers_examples),
                        ],
                      ]),
                    ),
                  );
                  let _pipe$4 = $ask.expect(_pipe$3, $dynamic.string);
                  let _pipe$5 = $ask.notify(
                    _pipe$4,
                    (sg) => {
                      return dispatch(
                        new $msg.ApiReturnedGeneratedStyleGuidelines(sg),
                      );
                    },
                  );
                  $ask.run(_pipe$5)
                  return undefined;
                },
              );
            },
          );
        },
      );
    },
  );
}

export function fetch_style_guidelines(data) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["style-guidelines"]),
              );
              let _pipe$1 = $ask.via(_pipe, new $http.Get());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.expect(
                _pipe$2,
                $dynamic.field("content", $dynamic.optional($dynamic.string)),
              );
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (sg) => {
                  return dispatch(new $msg.ApiReturnedStyleGuidelines(sg));
                },
              );
              $ask.run(_pipe$4)
              return undefined;
            },
          );
        },
      );
    },
  );
}
