/// <reference types="./ingestion_control.d.mts" />
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $ask from "../../admin/ask.mjs";
import * as $middleware from "../../admin/middleware.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList } from "../../gleam.mjs";

export function fetch_data_points(data, source_id) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["data-points", source_id]),
              );
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.expect(
                _pipe$1,
                $dynamic.list($data_point.decode),
              );
              let _pipe$3 = $ask.notify(
                _pipe$2,
                (d) => {
                  return dispatch(
                    new $msg.ContentLibrary(new $msg.ApiFetchedDataPoints(d)),
                  );
                },
              );
              $ask.run(_pipe$3)
              return undefined;
            },
          );
        },
      );
    },
  );
}
