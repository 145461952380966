/// <reference types="./portal.d.mts" />
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import { register } from "../../ds.ffi.mjs";
import * as $unsafe from "../../ds/internals/unsafe.mjs";
import { Ok, toList } from "../../gleam.mjs";

export { register };

export function portal(id, child) {
  let id$1 = $attribute.id("portal-" + $int.to_string(id));
  let content = $attribute.property("content", child);
  let handler = $event.on(
    "portal-event",
    (msg) => { return new Ok($unsafe.coerce_event_details(msg)); },
  );
  return $element.element(
    "lustre-portal",
    toList([id$1, content, handler]),
    toList([]),
  );
}
