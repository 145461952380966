/// <reference types="./ingestion_control.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function data_point(child) {
  let _pipe = $s.class$(
    toList([
      $s.white_space("pre-line"),
      $s.border("1px solid " + $colors.light.grey_800),
      $s.border_radius(px(8)),
      $s.padding(px(10)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function page_number(child) {
  let _pipe = $s.class$(toList([$s.color($colors.light.grey_600)]));
  return $h.div(_pipe, toList([]), toList([child]));
}
