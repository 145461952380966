/// <reference types="./ingestion_control.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $button from "../../ds/ds/components/button.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import { toList } from "../gleam.mjs";
import * as $s from "../view/styles/ingestion_control.mjs";

export function view(model, source_id) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $el.fragment(
    toList([
      (() => {
        let _pipe = $h.div(
          toList([]),
          toList([
            $button.primary(
              toList([
                $e.on_click(new $msg.UserClickedOnFetchDataPoints(source_id)),
              ]),
              toList([$h.text("Get data points")]),
            ),
          ]),
        );
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
      $layout.column(
        toList([$layout.gap(16)]),
        toList([]),
        (() => {
          let _pipe = data.ingestion_control_data_points;
          return $list.map(
            _pipe,
            (dp) => {
              return $s.data_point(
                $layout.column(
                  toList([$layout.gap(4)]),
                  toList([]),
                  toList([
                    $h.div(toList([]), toList([$h.text(dp.text)])),
                    (() => {
                      let $1 = dp.display;
                      if ($1 instanceof $data_point.DisplayText) {
                        let page_number = $1.page_number;
                        return $s.page_number(
                          $h.text("p." + $int.to_string(page_number)),
                        );
                      } else {
                        return $el.none();
                      }
                    })(),
                  ]),
                ),
              );
            },
          );
        })(),
      ),
    ]),
  );
}
