/// <reference types="./content_library.d.mts" />
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $ingestion_control from "../admin/effects/ingestion_control.mjs";
import * as $middleware from "../admin/middleware.mjs";
import { require_not_modifying_question } from "../admin/middleware.mjs";
import * as $content_library from "../data/content_library.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $loading from "../data/ui/loading.mjs";

export function update(data, msg) {
  if (msg instanceof $msg.ApiReturnedSource) {
    let source = msg.source;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.upsert_data_source(_pipe, source);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedSources) {
    let sources = msg.sources;
    return require_not_modifying_question(
      data,
      () => {
        let _pipe = data.content_library;
        let _pipe$1 = $content_library.set_data_sources(_pipe, sources);
        let _pipe$2 = ((_capture) => {
          return $data.set_content_library(data, _capture);
        })(_pipe$1);
        let _pipe$3 = $data.mark_as_loaded(_pipe$2, $loading.content_library);
        return $pair.new$(_pipe$3, $effect.none());
      },
    );
  } else if (msg instanceof $msg.UserClickedOnSource) {
    let source_id = msg.source_id;
    let _pipe = $route.push(
      new $route.ContentLibrary(new $route.IngestionControl(source_id)),
    );
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.UserClickedOnFetchDataPoints) {
    let source_id = msg.source_id;
    let _pipe = $ingestion_control.fetch_data_points(data, source_id);
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else {
    let data_points = msg.data_points;
    let _pipe = (() => {
      let _record = data;
      return new $data.Data(
        _record.access_token,
        _record.client,
        _record.content_library,
        data_points,
        _record.ai_search_results,
        _record.ai_search_input,
        _record.loading,
        _record.permissions,
        _record.route,
        _record.user,
        _record.users,
        _record.style_guidelines_input,
        _record.questions_answers_examples,
        _record.style_guidelines,
      );
    })();
    return $pair.new$(_pipe, $effect.none());
  }
}
