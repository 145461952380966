/// <reference types="./msg.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $auth0_user from "../../auth0_client/auth0/client/user.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class ApiReturnedGeneratedStyleGuidelines extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class ApiReturnedStyleGuidelines extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class ApiSavedStyleGuidelines extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class ApplicationChangedRoute extends $CustomType {
  constructor(route) {
    super();
    this.route = route;
  }
}

export class Auth0 extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AuthStateChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserResetStyleGuidelines extends $CustomType {}

export class UserSubmittedQuestionsAnswersExamples extends $CustomType {}

export class UserSubmittedStyleGuidelines extends $CustomType {}

export class UserWroteQuestionsAnswersExamples extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserWroteStyleGuidelines extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class ApiReturnedAiSearchResults extends $CustomType {
  constructor(ai_search_results) {
    super();
    this.ai_search_results = ai_search_results;
  }
}

export class UserUpdatedSearchInput extends $CustomType {
  constructor(content) {
    super();
    this.content = content;
  }
}

export class UserSubmittedAiSearch extends $CustomType {}

export class ApiFetchedDataPoints extends $CustomType {
  constructor(data_points) {
    super();
    this.data_points = data_points;
  }
}

export class ApiReturnedSource extends $CustomType {
  constructor(source) {
    super();
    this.source = source;
  }
}

export class ApiReturnedSources extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}

export class UserClickedOnFetchDataPoints extends $CustomType {
  constructor(source_id) {
    super();
    this.source_id = source_id;
  }
}

export class UserClickedOnSource extends $CustomType {
  constructor(source_id) {
    super();
    this.source_id = source_id;
  }
}
