/// <reference types="./input.d.mts" />
import * as $s from "../../../../sketch/sketch.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../../sketch/sketch/size.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../../ds/colors.mjs";
import * as $display from "../../../ds/typography/display.mjs";
import * as $text from "../../../ds/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

export function wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.align_items("start"),
      $s.gap(px(6)),
      $s.width(percent(100)),
    ]),
  );
  return $h.label(_pipe, attrs, children);
}

export function input_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.position("relative"), $s.width(percent(100))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_display_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.position("absolute"),
      $s.top(px(0)),
      $s.height(percent(100)),
      $s.right(px(10)),
      $s.color($colors.light.grey_400),
      $s.z_index(10_000),
      $s.hover(toList([$s.color($colors.light.paragraph)])),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_display(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.width(px(24)),
      $s.height(px(24)),
      $s.color($colors.light.grey_600),
      $s.transition("all .2s"),
      $s.hover(toList([$s.color($colors.light.paragraph)])),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function input_base(with_icon) {
  return $s.class$(
    toList([
      $s.compose($text.md($text.regular)),
      $s.display("block"),
      $s.appearance("none"),
      $s.color($colors.light.paragraph),
      $s.property("border-width", "1px"),
      $s.property("border-style", "solid"),
      $s.font_family("Roobert"),
      $s.padding_right(
        (() => {
          if (!with_icon) {
            return px(14);
          } else {
            return px(24 + 14);
          }
        })(),
      ),
      $s.border_radius(px(8)),
      $s.transition("all .3s"),
      $s.width(percent(100)),
      $s.outline("none"),
    ]),
  );
}

export function standard(with_icon, attrs) {
  let _pipe = $s.class$(
    toList([
      $s.compose(input_base(with_icon)),
      $s.property("border-color", $colors.light.grey_400),
      $s.padding_("10px 14px"),
      $s.placeholder(toList([$s.color($colors.light.grey_800)])),
      $s.focus(
        toList([
          $s.border("1px solid " + $colors.light.ai_400),
          $s.outline("none"),
          $s.box_shadow("0px 0px 0px 4px " + $colors.light.ai_100),
        ]),
      ),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function standard_multiline(with_icon, attrs) {
  let _pipe = $s.class$(
    toList([
      $s.compose(input_base(with_icon)),
      $s.property("border-color", $colors.light.grey_400),
      $s.property("resize", "none"),
      $s.padding_("10px 14px"),
      $s.placeholder(toList([$s.color($colors.light.grey_800)])),
      $s.focus(
        toList([
          $s.border("1px solid " + $colors.light.ai_400),
          $s.outline("none"),
          $s.box_shadow("0px 0px 0px 4px " + $colors.light.ai_100),
        ]),
      ),
    ]),
  );
  return $h.textarea(_pipe, attrs, toList([]));
}

export function disabled(with_icon, attrs) {
  let _pipe = $s.class$(
    toList([
      $s.compose(input_base(with_icon)),
      $s.property("border-color", $colors.light.grey_400),
      $s.padding_("10px 14px"),
      $s.placeholder(toList([$s.color($colors.light.grey_600)])),
      $s.disabled(
        toList([
          $s.border("1px solid " + $colors.light.grey_400),
          $s.color($colors.light.grey_600),
          $s.cursor("not-allowed"),
        ]),
      ),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function invisible(with_icon, attrs) {
  let _pipe = $s.class$(
    toList([
      $s.compose(input_base(with_icon)),
      $s.property("border-color", "transparent"),
      $s.padding(px(0)),
      $s.compose($display.sm()),
      $s.placeholder(toList([$s.color($colors.light.grey_600)])),
      $s.disabled(
        toList([
          $s.border("1px solid " + $colors.light.grey_400),
          $s.color($colors.light.grey_600),
          $s.cursor("not-allowed"),
        ]),
      ),
      $s.active(toList([$s.property("border-color", $colors.light.grey_600)])),
      $s.focus(
        toList([
          $s.property("border-color", $colors.light.grey_600),
          $s.padding_("10px 14px"),
        ]),
      ),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function disabled_multiline(with_icon, attrs) {
  let _pipe = $s.class$(
    toList([
      $s.compose(input_base(with_icon)),
      $s.property("resize", "none"),
      $s.property("border-color", $colors.light.grey_400),
      $s.padding_("10px 14px"),
      $s.placeholder(toList([$s.color($colors.light.grey_600)])),
      $s.disabled(
        toList([
          $s.border("1px solid " + $colors.light.grey_400),
          $s.color($colors.light.grey_600),
          $s.cursor("not-allowed"),
        ]),
      ),
    ]),
  );
  return $h.textarea(_pipe, attrs, toList([]));
}

export function error(with_icon, attrs) {
  let _pipe = $s.class$(
    toList([
      $s.compose(input_base(with_icon)),
      (() => {
        let _pipe = $s.color($colors.light.error_800);
        return $s.important(_pipe);
      })(),
      $s.property("border-color", $colors.light.error_400),
      $s.padding_("10px 14px"),
      $s.box_shadow("0px 0px 0px 4px " + $colors.light.error_100),
      $s.placeholder(toList([$s.color($colors.light.error_600)])),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function error_multiline(with_icon, attrs) {
  let _pipe = $s.class$(
    toList([
      $s.compose(input_base(with_icon)),
      $s.property("resize", "none"),
      (() => {
        let _pipe = $s.color($colors.light.error_800);
        return $s.important(_pipe);
      })(),
      $s.property("border-color", $colors.light.error_400),
      $s.padding_("10px 14px"),
      $s.box_shadow("0px 0px 0px 4px " + $colors.light.error_100),
      $s.placeholder(toList([$s.color($colors.light.error_600)])),
    ]),
  );
  return $h.textarea(_pipe, attrs, toList([]));
}

export function error_label(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.color($colors.light.error_800),
      $s.compose($text.sm($text.regular)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
