import * as auth0 from '@auth0/auth0-spa-js'
import * as option from '../gleam_stdlib/gleam/option.mjs'
import * as gleam from './gleam.mjs'

function dictToObject(dict) {
  const obj = {}
  dict.forEach((v, k) => (obj[k] = v))
  return obj
}

export async function client(options) {
  try {
    const authorization_params = option.unwrap(
      options.authorization_params,
      undefined
    )
    const client = await auth0.createAuth0Client({
      domain: options.domain,
      clientId: options.client_id,
      cacheLocation: options.use_refresh_token ? 'localstorage' : 'memory',
      useRefreshTokens: options.use_refresh_token,
      useRefreshTokensFallback: options.use_refresh_token_fallback,
      authorizationParams: authorization_params && {
        audience: option.unwrap(authorization_params.audience, undefined),
        scope: option.unwrap(authorization_params.scope, undefined),
        connection: option.unwrap(authorization_params.connection, undefined),
        organization: option.unwrap(
          authorization_params.organization,
          undefined
        ),
        invitation: option.unwrap(authorization_params.invitation, undefined),
        redirect_uri: option.unwrap(
          authorization_params.redirect_uri,
          undefined
        ),
        ...option.unwrap(
          option.map(authorization_params.custom_parameters, dictToObject),
          {}
        ),
      },
    })
    await handleRedirectCallback(client, window.location.search)
    return client
  } catch (error) {
    console.error('[auth0.ffi.mjs#client]', error)
    throw error
  }
}

async function handleRedirectCallback(client, query) {
  if (query.includes('code=') && query.includes('state=')) {
    await client.handleRedirectCallback()
    window.history.replaceState({}, document.title, '/')
  }
}

/** @param client_ {Promise<auth0.Auth0Client>} */
export async function state(client_) {
  try {
    const client = await client_
    const isAuthenticated = await client.isAuthenticated()
    if (!isAuthenticated) return new option.None()
    const accessToken = await client.getTokenSilently()
    const user = await client.getUser()
    return new option.Some([accessToken, user])
  } catch (error) {
    console.error('[auth0.ffi.mjs#state]', error)
    return new option.None()
  }
}

/** @param client_ {Promise<auth0.Auth0Client>} */
export async function loginWithRedirect(client_) {
  try {
    const client = await client_
    await client.loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    })
  } catch (error) {
    console.error('[auth0.ffi.mjs#loginWithRedirect]', error)
  }
  return undefined
}

/** @param client_ {Promise<auth0.Auth0Client>} */
export async function loginWithPopup(client_) {
  try {
    const client = await client_
    await client.loginWithPopup({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    })
  } catch (error) {
    console.error('[auth0.ffi.mjs#loginWithPopup]', error)
  }
  return undefined
}

/** @param client_ {Promise<auth0.Auth0Client>} */
export async function buildAuthorize(client_, redirect_uri) {
  const client = await client_
  return new Promise(async resolve => {
    try {
      await client.loginWithRedirect({
        authorizationParams: { redirect_uri },
        openUrl: url => resolve(new gleam.Ok(url)),
      })
    } catch (error) {
      console.error('[auth0.ffi.mjs#buildAuthorize]', error)
      resolve(new gleam.Error(undefined))
    }
  })
}

/** @param client_ {Promise<auth0.Auth0Client>} */
export async function handleRedirect(client_, url) {
  try {
    const client = await client_
    return await client.handleRedirectCallback(url)
  } catch (error) {
    console.error('[auth0.ffi.mjs#handleRedirect]', error)
    throw error
  }
}

/** @param client_ {Promise<auth0.Auth0Client>} */
export async function logout(client_) {
  try {
    const client = await client_
    await client.logout({ logoutParams: { returnTo: window.location.origin } })
  } catch (error) {
    console.error('[auth0.ffi.mjs#logout]', error)
  }
  return undefined
}
