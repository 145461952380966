/// <reference types="./data.d.mts" />
import * as $auth0 from "../../../auth0_client/auth0/client.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import { readPermissions as read_permissions } from "../../admin.ffi.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $route from "../../data/route.mjs";
import * as $loading from "../../data/ui/loading.mjs";
import { toList, CustomType as $CustomType, isEqual } from "../../gleam.mjs";

export class Data extends $CustomType {
  constructor(access_token, client, content_library, ingestion_control_data_points, ai_search_results, ai_search_input, loading, permissions, route, user, users, style_guidelines_input, questions_answers_examples, style_guidelines) {
    super();
    this.access_token = access_token;
    this.client = client;
    this.content_library = content_library;
    this.ingestion_control_data_points = ingestion_control_data_points;
    this.ai_search_results = ai_search_results;
    this.ai_search_input = ai_search_input;
    this.loading = loading;
    this.permissions = permissions;
    this.route = route;
    this.user = user;
    this.users = users;
    this.style_guidelines_input = style_guidelines_input;
    this.questions_answers_examples = questions_answers_examples;
    this.style_guidelines = style_guidelines;
  }
}

export function new$(client, route) {
  return new Data(
    new $option.None(),
    client,
    $content_library.new$(),
    toList([]),
    toList([]),
    "",
    $loading.new$(),
    toList([]),
    route,
    new $option.None(),
    toList([]),
    "",
    "",
    new $option.None(),
  );
}

export function update_route(data, route) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.content_library,
    _record.ingestion_control_data_points,
    _record.ai_search_results,
    _record.ai_search_input,
    _record.loading,
    _record.permissions,
    route,
    _record.user,
    _record.users,
    _record.style_guidelines_input,
    _record.questions_answers_examples,
    _record.style_guidelines,
  );
}

export function update_access_token(data, access_token) {
  let _pipe = read_permissions(access_token);
  let _pipe$1 = $result.replace_error(_pipe, toList([]));
  let _pipe$2 = $result.then$(_pipe$1, $dynamic.list($dynamic.string));
  let _pipe$3 = $result.unwrap(_pipe$2, toList([]));
  return ((permissions) => {
    let _record = data;
    return new Data(
      new Some(access_token),
      _record.client,
      _record.content_library,
      _record.ingestion_control_data_points,
      _record.ai_search_results,
      _record.ai_search_input,
      _record.loading,
      permissions,
      _record.route,
      _record.user,
      _record.users,
      _record.style_guidelines_input,
      _record.questions_answers_examples,
      _record.style_guidelines,
    );
  })(_pipe$3);
}

export function update_connected_user(data, user) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.content_library,
    _record.ingestion_control_data_points,
    _record.ai_search_results,
    _record.ai_search_input,
    _record.loading,
    _record.permissions,
    _record.route,
    new Some(user),
    _record.users,
    _record.style_guidelines_input,
    _record.questions_answers_examples,
    _record.style_guidelines,
  );
}

export function is_connected(model) {
  return $option.is_some(model.access_token);
}

export function mark_as_loaded(data, mapper) {
  let _record = data;
  return new Data(
    _record.access_token,
    _record.client,
    _record.content_library,
    _record.ingestion_control_data_points,
    _record.ai_search_results,
    _record.ai_search_input,
    mapper(data.loading),
    _record.permissions,
    _record.route,
    _record.user,
    _record.users,
    _record.style_guidelines_input,
    _record.questions_answers_examples,
    _record.style_guidelines,
  );
}

export function set_ai_search_input(model, ai_search_input) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    _record.content_library,
    _record.ingestion_control_data_points,
    _record.ai_search_results,
    ai_search_input,
    _record.loading,
    _record.permissions,
    _record.route,
    _record.user,
    _record.users,
    _record.style_guidelines_input,
    _record.questions_answers_examples,
    _record.style_guidelines,
  );
}

export function set_content_library(model, content_library) {
  let _record = model;
  return new Data(
    _record.access_token,
    _record.client,
    content_library,
    _record.ingestion_control_data_points,
    _record.ai_search_results,
    _record.ai_search_input,
    _record.loading,
    _record.permissions,
    _record.route,
    _record.user,
    _record.users,
    _record.style_guidelines_input,
    _record.questions_answers_examples,
    _record.style_guidelines,
  );
}

export function update_org_user(model, user_id, function$) {
  let _pipe = model.users;
  let _pipe$1 = $list.map(
    _pipe,
    (user) => {
      return $bool.guard(
        user.id !== user_id,
        user,
        () => {
          let _record = user;
          return new $user.User(
            _record.id,
            _record.email,
            _record.name,
            _record.picture,
            _record.initials,
            _record.roles,
            new Some(function$),
          );
        },
      );
    },
  );
  return ((users) => {
    let _record = model;
    return new Data(
      _record.access_token,
      _record.client,
      _record.content_library,
      _record.ingestion_control_data_points,
      _record.ai_search_results,
      _record.ai_search_input,
      _record.loading,
      _record.permissions,
      _record.route,
      _record.user,
      users,
      _record.style_guidelines_input,
      _record.questions_answers_examples,
      _record.style_guidelines,
    );
  })(_pipe$1);
}

export function find_current_user(model) {
  return $list.find(
    model.users,
    (user) => {
      let connected_user = $option.map(model.user, (u) => { return u.sub; });
      return isEqual(new Some(user.id), connected_user);
    },
  );
}

export function find_current_user_id(model) {
  let user = find_current_user(model);
  return $result.map(user, (u) => { return u.id; });
}

export function current_org_id(model) {
  return $option.then$(model.user, (u) => { return u.org_id; });
}
