/// <reference types="./ds.d.mts" />
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $magic from "../sketch_magic/sketch/magic.mjs";
import * as $dropdown from "./ds/components/dropdown.mjs";
import * as $portal from "./ds/components/portal.mjs";
import * as $tooltip from "./ds/components/tooltip.mjs";
import * as $transition_group from "./ds/components/transition_group.mjs";
import { Ok } from "./gleam.mjs";

export function setup() {
  let stylesheet = $magic.document();
  return $result.try$(
    $dropdown.register(stylesheet),
    (_) => {
      return $result.try$(
        $tooltip.register(stylesheet),
        (_) => {
          return $result.try$(
            $portal.register(),
            (_) => {
              return $result.try$(
                $transition_group.register(),
                (_) => { return new Ok(undefined); },
              );
            },
          );
        },
      );
    },
  );
}
