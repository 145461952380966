/// <reference types="./content_library.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function empty_cell(child) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.padding_("30px 0"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function empty_text_content(child) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.max_width(px(230)),
      $s.white_space("wrap"),
      $s.text_align("center"),
      $s.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}
