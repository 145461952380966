import * as analytics from '@vercel/analytics'
import * as speed from '@vercel/speed-insights'
import { main } from './admin.gleam'

// Setup Vercel toolings.
if (import.meta.env.PROD) {
  analytics.inject({ mode: 'production' })
  speed.injectSpeedInsights()
}

// Start the Lustre app.
document.addEventListener('DOMContentLoaded', main)
