/// <reference types="./auth.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $auth0_options from "../../auth0_client/auth0/client/options.mjs";
import * as $vitools from "../../vitools/vitools.mjs";
import { CustomType as $CustomType, makeError } from "../gleam.mjs";

export class Authentication extends $CustomType {}

export class Invitation extends $CustomType {
  constructor(organization, invitation) {
    super();
    this.organization = organization;
    this.invitation = invitation;
  }
}

function create_client(params) {
  let $ = $vitools.get_env("AUTH0_CLIENT_ID");
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "admin/auth",
      35,
      "create_client",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let client_id = $[0];
  let $1 = $vitools.get_env("AUTH0_DOMAIN");
  if (!$1.isOk()) {
    throw makeError(
      "let_assert",
      "admin/auth",
      36,
      "create_client",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let domain = $1[0];
  let _pipe = $auth0_options.new$(domain, client_id);
  let _pipe$1 = $auth0_options.use_refresh_token(_pipe, true);
  let _pipe$2 = $auth0_options.use_refresh_token_fallback(_pipe$1, false);
  let _pipe$3 = $auth0_options.with_authorization_params(_pipe$2, params);
  return $auth0.create(_pipe$3);
}

function auth_client() {
  let _pipe = $auth0_options.authorization_params();
  let _pipe$1 = $auth0_options.audience(_pipe, "https://api.steerlab.ai/");
  let _pipe$2 = $auth0_options.scope(_pipe$1, "openid profile email");
  return create_client(_pipe$2);
}

function invite_client(organization, invitation) {
  let _pipe = $auth0_options.authorization_params();
  let _pipe$1 = $auth0_options.audience(_pipe, "https://api.steerlab.ai/");
  let _pipe$2 = $auth0_options.scope(_pipe$1, "openid profile email");
  let _pipe$3 = $auth0_options.organization(_pipe$2, organization);
  let _pipe$4 = $auth0_options.invitation(_pipe$3, invitation);
  return create_client(_pipe$4);
}

export function client(mode) {
  if (mode instanceof Authentication) {
    return auth_client();
  } else {
    let organization = mode.organization;
    let invitation = mode.invitation;
    return invite_client(organization, invitation);
  }
}
