/// <reference types="./utils.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import { Ok, Error, toList, isEqual } from "../gleam.mjs";

export function find_column_index(types, value) {
  let _pipe = types;
  let _pipe$1 = $list.find(_pipe, (v) => { return isEqual(v[1], value); });
  return $result.map(_pipe$1, $pair.first);
}

export function find_nth(loop$l, loop$index, loop$start) {
  while (true) {
    let l = loop$l;
    let index = loop$index;
    let start = loop$start;
    if (l.atLeastLength(1) && (index === start)) {
      let first = l.head;
      return new Ok(first);
    } else if (l.atLeastLength(1)) {
      let rest = l.tail;
      loop$l = rest;
      loop$index = index;
      loop$start = start + 1;
    } else {
      return new Error(undefined);
    }
  }
}

export function default_llm_config() {
  return $json.object(
    toList([
      ["llm_config", $json.object(toList([["limit", $json.int(10)]]))],
      [
        "retriever_config",
        $json.object(toList([["option", $json.string("rerank")]])),
      ],
      [
        "query_config",
        $json.object(
          toList([
            ["limit", $json.int(50)],
            ["alpha", $json.float(0.5)],
            ["hyde", $json.bool(false)],
            ["parent_lookup", $json.bool(false)],
            ["extract_question", $json.bool(false)],
            ["translate_on", $json.bool(true)],
            ["language_source", $json.string("en")],
          ]),
        ),
      ],
      [
        "models_config",
        $json.object(
          toList([
            ["rag", $json.string("azure/azure-gpt-4o")],
            ["rag_temperature", $json.int(0)],
            ["rag_version", $json.int(12)],
            ["style_1", $json.string("")],
            ["style_2", $json.string("azure/azure-gpt-4o")],
            ["summary", $json.string("azure/azure-gpt-4o")],
            ["classifier", $json.string("azure/azure-gpt-4o")],
            ["default", $json.string("azure/azure-gpt-4o")],
            [
              "default_fallback_models",
              $json.array(
                toList(["gpt-4o", "azure/azure-gpt-4o"]),
                $json.string,
              ),
            ],
          ]),
        ),
      ],
    ]),
  );
}
