/// <reference types="./effects.d.mts" />
import * as $effect from "../../lustre/lustre/effect.mjs";
import { toggleDomFreeze as do_toggle_dom_freeze } from "../ds.ffi.mjs";
import * as $unsafe from "../ds/internals/unsafe.mjs";

export function subscribe_dom_click(on_unsubscriber, on_click) {
  return $effect.from(
    (dispatch) => {
      let unsubscriber = $unsafe.subscribe_dom_click(
        () => { return dispatch(on_click); },
      );
      return dispatch(on_unsubscriber(unsubscriber));
    },
  );
}

export function toggle_dom_freeze() {
  return $effect.from((_) => { return do_toggle_dom_freeze(); });
}
