/// <reference types="./utils.d.mts" />
import * as $event from "../../lustre/lustre/event.mjs";
import { Error, toList } from "../gleam.mjs";

export function stop_propagation() {
  return $event.on(
    "click",
    (event) => {
      $event.stop_propagation(event);
      return new Error(toList([]));
    },
  );
}
