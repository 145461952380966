/// <reference types="./data_point.d.mts" />
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class DataPoint extends $CustomType {
  constructor(id, source_id, display, kind, text) {
    super();
    this.id = id;
    this.source_id = source_id;
    this.display = display;
    this.kind = kind;
    this.text = text;
  }
}

export class Text extends $CustomType {}

export class Qa extends $CustomType {}

export class DisplayText extends $CustomType {
  constructor(as_html, page_number) {
    super();
    this.as_html = as_html;
    this.page_number = page_number;
  }
}

export class DisplayQa extends $CustomType {
  constructor(as_html, row_number, sheet_name, question, answer) {
    super();
    this.as_html = as_html;
    this.row_number = row_number;
    this.sheet_name = sheet_name;
    this.question = question;
    this.answer = answer;
  }
}

function decode_kind(kind) {
  let expected = "text | qa";
  if (kind === "text") {
    return new Ok(new Text());
  } else if (kind === "qa") {
    return new Ok(new Qa());
  } else {
    let found = kind;
    return new Error(
      toList([new $dynamic.DecodeError(expected, found, toList([]))]),
    );
  }
}

function encode_display(display) {
  if (display instanceof DisplayText) {
    let as_html = display.as_html;
    let page_number = display.page_number;
    return $json.object(
      toList([
        ["as_html", $json.nullable(as_html, $json.string)],
        ["page_number", $json.int(page_number)],
      ]),
    );
  } else {
    let as_html = display.as_html;
    let row_number = display.row_number;
    let sheet_name = display.sheet_name;
    let question = display.question;
    let answer = display.answer;
    return $json.object(
      toList([
        ["as_html", $json.nullable(as_html, $json.string)],
        ["row_number", $json.int(row_number)],
        ["sheet_name", $json.string(sheet_name)],
        ["question", $json.string(question)],
        ["answer", $json.string(answer)],
      ]),
    );
  }
}

function encode_kind(kind) {
  if (kind instanceof Text) {
    return $json.string("text");
  } else {
    return $json.string("qa");
  }
}

export function encode(data_point) {
  return $json.object(
    toList([
      ["id", $json.string(data_point.id)],
      ["source_id", $json.string(data_point.source_id)],
      ["display", encode_display(data_point.display)],
      ["kind", encode_kind(data_point.kind)],
      ["text", $json.string(data_point.text)],
    ]),
  );
}

function decode_display(dyn) {
  let _pipe = $decode.one_of(
    toList([
      (() => {
        let _pipe = $decode.into(
          $decode.parameter(
            (as_html) => {
              return $decode.parameter(
                (page_number) => {
                  return new DisplayText(as_html, page_number);
                },
              );
            },
          ),
        );
        let _pipe$1 = $decode.field(
          _pipe,
          "as_html",
          $decode.optional($decode.string),
        );
        return $decode.field(_pipe$1, "page_number", $decode.int);
      })(),
      (() => {
        let _pipe = $decode.into(
          $decode.parameter(
            (as_html) => {
              return $decode.parameter(
                (row_number) => {
                  return $decode.parameter(
                    (sheet_name) => {
                      return $decode.parameter(
                        (question) => {
                          return $decode.parameter(
                            (answer) => {
                              return new DisplayQa(
                                as_html,
                                row_number,
                                sheet_name,
                                question,
                                answer,
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          ),
        );
        let _pipe$1 = $decode.field(
          _pipe,
          "as_html",
          $decode.optional($decode.string),
        );
        let _pipe$2 = $decode.field(_pipe$1, "row_number", $decode.int);
        let _pipe$3 = $decode.field(_pipe$2, "sheet_name", $decode.string);
        let _pipe$4 = $decode.field(_pipe$3, "question", $decode.string);
        return $decode.field(_pipe$4, "answer", $decode.string);
      })(),
    ]),
  );
  return $decode.from(_pipe, dyn);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (source_id) => {
            return $decode.parameter(
              (display) => {
                return $decode.parameter(
                  (kind) => {
                    return $decode.parameter(
                      (text) => {
                        return new DataPoint(id, source_id, display, kind, text);
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "id", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "source_id", $decode.string);
  let _pipe$3 = $decode.field(_pipe$2, "display", $decode_.json(decode_display));
  let _pipe$4 = $decode.field(
    _pipe$3,
    "kind",
    $decode_.from_string(decode_kind),
  );
  let _pipe$5 = $decode.field(_pipe$4, "text", $decode.string);
  return $decode.from(_pipe$5, dyn);
}
