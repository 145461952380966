/// <reference types="./data_source.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import * as $connector from "../data/connector.mjs";
import * as $question from "../data/question.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Pending extends $CustomType {}

export class Verified extends $CustomType {}

export class NotVerified extends $CustomType {}

export class Archived extends $CustomType {}

export class Outdated extends $CustomType {}

export class StatusStats extends $CustomType {
  constructor(verified, pending, not_verified, outdated) {
    super();
    this.verified = verified;
    this.pending = pending;
    this.not_verified = not_verified;
    this.outdated = outdated;
  }
}

export class SheetLabels extends $CustomType {
  constructor(sheet_index, sheet_title, kind, question_column_index, answer_column_index, rows_to_process) {
    super();
    this.sheet_index = sheet_index;
    this.sheet_title = sheet_title;
    this.kind = kind;
    this.question_column_index = question_column_index;
    this.answer_column_index = answer_column_index;
    this.rows_to_process = rows_to_process;
  }
}

export class Qna extends $CustomType {
  constructor(question, answer, tag) {
    super();
    this.question = question;
    this.answer = answer;
    this.tag = tag;
  }
}

export class Display extends $CustomType {
  constructor(name, language, mime_type, origin, qna) {
    super();
    this.name = name;
    this.language = language;
    this.mime_type = mime_type;
    this.origin = origin;
    this.qna = qna;
  }
}

export class DataSource extends $CustomType {
  constructor(id, status, display, kind, authors, owner, document_id, blob_link, content_link, hash, version, tags, org_id, expiration_date) {
    super();
    this.id = id;
    this.status = status;
    this.display = display;
    this.kind = kind;
    this.authors = authors;
    this.owner = owner;
    this.document_id = document_id;
    this.blob_link = blob_link;
    this.content_link = content_link;
    this.hash = hash;
    this.version = version;
    this.tags = tags;
    this.org_id = org_id;
    this.expiration_date = expiration_date;
  }
}

export function status_to_string(status) {
  if (status instanceof Pending) {
    return "pending";
  } else if (status instanceof Verified) {
    return "verified";
  } else if (status instanceof NotVerified) {
    return "not-verified";
  } else if (status instanceof Archived) {
    return "archived";
  } else {
    return "outdated";
  }
}

function encode_display(display) {
  return $json.object(
    toList([
      ["name", $json.string(display.name)],
      ["language", $json.nullable(display.language, $json.string)],
      ["mime_type", $json.string($mime_types.to_string(display.mime_type))],
      ["origin", $json.nullable(display.origin, $json.string)],
      [
        "qna",
        $json.nullable(
          display.qna,
          (qna) => {
            return $json.object(
              toList([
                ["question", $json.string(qna.question)],
                ["answer", $json.string(qna.answer)],
                ["tag", $json.nullable(qna.tag, $json.string)],
              ]),
            );
          },
        ),
      ],
    ]),
  );
}

export function encode(data_source) {
  return $json.object(
    toList([
      ["id", $json.string(data_source.id)],
      ["status", $json.string(status_to_string(data_source.status))],
      ["display", encode_display(data_source.display)],
      ["kind", $connector.encode(data_source.kind)],
      ["authors", $json.array(data_source.authors, $json.string)],
      ["owner", $json.nullable(data_source.owner, $json.string)],
      ["document_id", $json.string(data_source.document_id)],
      ["blob_link", $json.string(data_source.blob_link)],
      ["content_link", $json.string(data_source.content_link)],
      ["hash", $json.nullable(data_source.hash, $json.string)],
      ["version", $json.int(data_source.version)],
      ["tags", $json.array(data_source.tags, $json.string)],
      ["org_id", $json.string(data_source.org_id)],
      [
        "expiration_date",
        $json.nullable(
          data_source.expiration_date,
          (date) => { return $json.string($birl.to_iso8601(date)); },
        ),
      ],
    ]),
  );
}

export function decode_status(dyn) {
  return $result.try$(
    $dynamic.string(dyn),
    (status) => {
      if (status === "archived") {
        return new Ok(new Archived());
      } else if (status === "not-verified") {
        return new Ok(new NotVerified());
      } else if (status === "pending") {
        return new Ok(new Pending());
      } else if (status === "verified") {
        return new Ok(new Verified());
      } else if (status === "outdated") {
        return new Ok(new Outdated());
      } else {
        return new Error(
          toList([
            new $dynamic.DecodeError("status", status, toList(["status"])),
          ]),
        );
      }
    },
  );
}

export function encode_content_library_stats(stats) {
  let _pipe = toList([
    [new Verified(), stats.verified],
    [new Pending(), stats.pending],
    [new NotVerified(), stats.not_verified],
    [new Outdated(), stats.outdated],
  ]);
  let _pipe$1 = $list.map(
    _pipe,
    (_capture) => { return $pair.map_first(_capture, status_to_string); },
  );
  let _pipe$2 = $list.map(
    _pipe$1,
    (_capture) => { return $pair.map_second(_capture, $json.int); },
  );
  return $json.object(_pipe$2);
}

function decode_optional_field(field, default$, decoder) {
  return (dyn) => {
    let $ = $dynamic.optional_field(field, $dynamic.optional(decoder))(dyn);
    if ($.isOk() &&
    $[0] instanceof $option.Some &&
    $[0][0] instanceof $option.Some) {
      let a = $[0][0][0];
      return new Ok(a);
    } else if ($.isOk()) {
      return new Ok(default$);
    } else {
      let e = $[0];
      return new Error(e);
    }
  };
}

export function decode_content_library_stats(dyn) {
  return $dynamic.decode4(
    (var0, var1, var2, var3) => {
      return new StatusStats(var0, var1, var2, var3);
    },
    decode_optional_field(status_to_string(new Verified()), 0, $dynamic.int),
    decode_optional_field(status_to_string(new Pending()), 0, $dynamic.int),
    decode_optional_field(status_to_string(new NotVerified()), 0, $dynamic.int),
    decode_optional_field(status_to_string(new Outdated()), 0, $dynamic.int),
  )(dyn);
}

export function decrease_status_stats(stats, data_source) {
  let $ = data_source.status;
  if ($ instanceof Pending) {
    let _record = stats;
    return new StatusStats(
      _record.verified,
      stats.pending - 1,
      _record.not_verified,
      _record.outdated,
    );
  } else if ($ instanceof Verified) {
    let _record = stats;
    return new StatusStats(
      stats.verified - 1,
      _record.pending,
      _record.not_verified,
      _record.outdated,
    );
  } else if ($ instanceof NotVerified) {
    let _record = stats;
    return new StatusStats(
      _record.verified,
      _record.pending,
      stats.not_verified - 1,
      _record.outdated,
    );
  } else if ($ instanceof Outdated) {
    let _record = stats;
    return new StatusStats(
      _record.verified,
      _record.pending,
      _record.not_verified,
      stats.outdated - 1,
    );
  } else {
    return stats;
  }
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (status) => {
            return $decode.parameter(
              (display) => {
                return $decode.parameter(
                  (kind) => {
                    return $decode.parameter(
                      (authors) => {
                        return $decode.parameter(
                          (owner) => {
                            return $decode.parameter(
                              (document_id) => {
                                return $decode.parameter(
                                  (blob_link) => {
                                    return $decode.parameter(
                                      (content_link) => {
                                        return $decode.parameter(
                                          (hash) => {
                                            return $decode.parameter(
                                              (version) => {
                                                return $decode.parameter(
                                                  (tags) => {
                                                    return $decode.parameter(
                                                      (org_id) => {
                                                        return $decode.parameter(
                                                          (expiration_date) => {
                                                            return new DataSource(
                                                              id,
                                                              status,
                                                              display,
                                                              kind,
                                                              authors,
                                                              owner,
                                                              document_id,
                                                              blob_link,
                                                              content_link,
                                                              hash,
                                                              version,
                                                              tags,
                                                              org_id,
                                                              expiration_date,
                                                            );
                                                          },
                                                        );
                                                      },
                                                    );
                                                  },
                                                );
                                              },
                                            );
                                          },
                                        );
                                      },
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "id",
    (() => {
      let _pipe$1 = $decode.string;
      return $decode.map(_pipe$1, $string.lowercase);
    })(),
  );
  let _pipe$2 = $decode.field(
    _pipe$1,
    "status",
    $decode_.dynamic(decode_status),
  );
  let _pipe$3 = $decode.field(_pipe$2, "display", $decode_.json(decode_display));
  let _pipe$4 = $decode.field(
    _pipe$3,
    "kind",
    $decode_.dynamic($connector.decode),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "authors",
    (() => {
      let _pipe$5 = $decode.list($decode.optional($decode.string));
      let _pipe$6 = $decode.map(_pipe$5, $option.all);
      let _pipe$7 = $decode.optional(_pipe$6);
      let _pipe$8 = $decode.map(_pipe$7, $option.flatten);
      return $decode.map(
        _pipe$8,
        (_capture) => { return $option.unwrap(_capture, toList([])); },
      );
    })(),
  );
  let _pipe$6 = $decode.field(
    _pipe$5,
    "owner",
    $decode.optional($decode.string),
  );
  let _pipe$7 = $decode.field(_pipe$6, "document_id", $decode.string);
  let _pipe$8 = $decode.field(_pipe$7, "blob_link", $decode.string);
  let _pipe$9 = $decode.field(_pipe$8, "content_link", $decode.string);
  let _pipe$10 = $decode.field(
    _pipe$9,
    "hash",
    $decode.optional($decode.string),
  );
  let _pipe$11 = $decode.field(_pipe$10, "version", $decode.int);
  let _pipe$12 = $decode.field(
    _pipe$11,
    "tags",
    (() => {
      let _pipe$12 = $decode.optional($decode.list($decode.string));
      return $decode.map(
        _pipe$12,
        (_capture) => { return $option.unwrap(_capture, toList([])); },
      );
    })(),
  );
  let _pipe$13 = $decode.field(_pipe$12, "org_id", $decode.string);
  let _pipe$14 = $decode.field(
    _pipe$13,
    "expiration_date",
    $decode.optional($decode_.time()),
  );
  return $decode.from(_pipe$14, dyn);
}

function decode_display(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (name) => {
        return $decode.parameter(
          (language) => {
            return $decode.parameter(
              (mime_type) => {
                return $decode.parameter(
                  (origin) => {
                    return $decode.parameter(
                      (qna) => {
                        return new Display(
                          name,
                          language,
                          mime_type,
                          origin,
                          qna,
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "name", $decode.string);
  let _pipe$2 = $decode.field(
    _pipe$1,
    "language",
    $decode.optional($decode.string),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "mime_type",
    $decode_.from_string($mime_types.from_string),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "origin",
    $decode.optional($decode.string),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "qna",
    $decode.optional(
      (() => {
        let _pipe$5 = $decode.into(
          $decode.parameter(
            (question) => {
              return $decode.parameter(
                (answer) => {
                  return $decode.parameter(
                    (tag) => { return new Qna(question, answer, tag); },
                  );
                },
              );
            },
          ),
        );
        let _pipe$6 = $decode.field(_pipe$5, "question", $decode.string);
        let _pipe$7 = $decode.field(_pipe$6, "answer", $decode.string);
        return $decode.field(_pipe$7, "tag", $decode.optional($decode.string));
      })(),
    ),
  );
  return $decode.from(_pipe$5, dyn);
}
