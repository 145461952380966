/// <reference types="./style_guidelines.d.mts" />
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function section_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}
