/// <reference types="./overview.d.mts" />
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $model from "../data/model.mjs";
import { toList } from "../gleam.mjs";

export function view(_) {
  return $el.fragment(
    toList([$h.div(toList([]), toList([$h.text("Admin page")]))]),
  );
}
