/// <reference types="./content_library.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $model from "../data/model.mjs";
import * as $route from "../data/route.mjs";
import { toList, makeError } from "../gleam.mjs";
import * as $show from "../view/content_library/show.mjs";
import * as $ingestion_control from "../view/ingestion_control.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.route;
  if (!($1 instanceof $route.ContentLibrary)) {
    throw makeError(
      "let_assert",
      "view/content_library",
      10,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  return $el.fragment(
    toList([
      (() => {
        if (route instanceof $route.DataSources) {
          return $show.view(model);
        } else {
          let source_id = route.source_id;
          return $ingestion_control.view(model, source_id);
        }
      })(),
    ]),
  );
}
