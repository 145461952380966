/// <reference types="./content_library.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import { DataSource } from "../../common/data/data_source.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, isEqual } from "../gleam.mjs";
import * as $utils from "../utils.mjs";

export class NewQna extends $CustomType {
  constructor(question, answer, loading, existing_document) {
    super();
    this.question = question;
    this.answer = answer;
    this.loading = loading;
    this.existing_document = existing_document;
  }
}

export class Sources extends $CustomType {
  constructor(all, all_size, verified, verified_size, pending, pending_size, not_verified, not_verified_size, outdated, outdated_size) {
    super();
    this.all = all;
    this.all_size = all_size;
    this.verified = verified;
    this.verified_size = verified_size;
    this.pending = pending;
    this.pending_size = pending_size;
    this.not_verified = not_verified;
    this.not_verified_size = not_verified_size;
    this.outdated = outdated;
    this.outdated_size = outdated_size;
  }
}

export class ContentLibrary extends $CustomType {
  constructor(non_qna_sources, selected, change_owner_popup_opened, change_status_popup_opened, change_tags_popup_opened, change_expiration_date_popup_opened, change_expiration_date_input, filter_selected, search, new_qna, ingestion_loading) {
    super();
    this.non_qna_sources = non_qna_sources;
    this.selected = selected;
    this.change_owner_popup_opened = change_owner_popup_opened;
    this.change_status_popup_opened = change_status_popup_opened;
    this.change_tags_popup_opened = change_tags_popup_opened;
    this.change_expiration_date_popup_opened = change_expiration_date_popup_opened;
    this.change_expiration_date_input = change_expiration_date_input;
    this.filter_selected = filter_selected;
    this.search = search;
    this.new_qna = new_qna;
    this.ingestion_loading = ingestion_loading;
  }
}

export function init_new_qna() {
  return new NewQna("", "", false, new $option.None());
}

export function set_filter(content_library, filter) {
  let _record = content_library;
  return new ContentLibrary(
    _record.non_qna_sources,
    _record.selected,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.change_expiration_date_popup_opened,
    _record.change_expiration_date_input,
    filter,
    _record.search,
    _record.new_qna,
    _record.ingestion_loading,
  );
}

function do_upsert_data_source(sources, source) {
  if (sources.atLeastLength(1) && (sources.head.id === source.id)) {
    let s = sources.head;
    let rest = sources.tail;
    return listPrepend(source, rest);
  } else if (sources.atLeastLength(1)) {
    let s = sources.head;
    let rest = sources.tail;
    return listPrepend(s, do_upsert_data_source(rest, source));
  } else {
    return toList([source]);
  }
}

function compute_sources(all) {
  let verified = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $data_source.Verified()); },
  );
  let pending = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $data_source.Pending()); },
  );
  let not_verified = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $data_source.NotVerified()); },
  );
  let outdated = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $data_source.Outdated()); },
  );
  return new Sources(
    all,
    $list.length(all),
    verified,
    $list.length(verified),
    pending,
    $list.length(pending),
    not_verified,
    $list.length(not_verified),
    outdated,
    $list.length(outdated),
  );
}

export function new$() {
  let now = $birl.utc_now();
  return new ContentLibrary(
    compute_sources(toList([])),
    $dict.new$(),
    false,
    false,
    false,
    false,
    $utils.time_to_qualified_day(now, "-"),
    new $option.None(),
    "",
    init_new_qna(),
    false,
  );
}

export function upsert_data_source(content_library, source) {
  let _pipe = do_upsert_data_source(content_library.non_qna_sources.all, source);
  let _pipe$1 = compute_sources(_pipe);
  return ((non_qna_sources) => {
    let _record = content_library;
    return new ContentLibrary(
      non_qna_sources,
      _record.selected,
      _record.change_owner_popup_opened,
      _record.change_status_popup_opened,
      _record.change_tags_popup_opened,
      _record.change_expiration_date_popup_opened,
      _record.change_expiration_date_input,
      _record.filter_selected,
      _record.search,
      _record.new_qna,
      _record.ingestion_loading,
    );
  })(_pipe$1);
}

export function set_non_qna_sources(content_library, sources) {
  let non_qna_sources = compute_sources(sources);
  let _record = content_library;
  return new ContentLibrary(
    non_qna_sources,
    _record.selected,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.change_expiration_date_popup_opened,
    _record.change_expiration_date_input,
    _record.filter_selected,
    _record.search,
    _record.new_qna,
    _record.ingestion_loading,
  );
}

export function set_data_sources(content_library, sources) {
  let sources$1 = $list.filter(
    sources,
    (s) => { return !isEqual(s.kind, new $connector.SteerlabQna()); },
  );
  let _pipe = content_library;
  return set_non_qna_sources(_pipe, sources$1);
}

export function close_popups(content_library) {
  let _record = content_library;
  return new ContentLibrary(
    _record.non_qna_sources,
    _record.selected,
    false,
    false,
    false,
    false,
    _record.change_expiration_date_input,
    _record.filter_selected,
    _record.search,
    _record.new_qna,
    _record.ingestion_loading,
  );
}

export function set_new_qna(content_library, new_qna) {
  let _record = content_library;
  return new ContentLibrary(
    _record.non_qna_sources,
    _record.selected,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.change_expiration_date_popup_opened,
    _record.change_expiration_date_input,
    _record.filter_selected,
    _record.search,
    new_qna,
    _record.ingestion_loading,
  );
}

export function filtered_non_qna_sources(content_library) {
  let sources = content_library.non_qna_sources;
  let $ = content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $data_source.Verified) {
    return sources.verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Pending) {
    return sources.pending;
  } else if ($ instanceof $option.Some &&
  $[0] instanceof $data_source.NotVerified) {
    return sources.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Outdated) {
    return sources.outdated;
  } else {
    return sources.all;
  }
}
