/// <reference types="./loading.d.mts" />
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Loading extends $CustomType {
  constructor(content_library) {
    super();
    this.content_library = content_library;
  }
}

export function new$() {
  return new Loading(true);
}

export function content_library(_) {
  return new Loading(false);
}
